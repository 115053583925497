import { useDispatch, useSelector } from "react-redux";
import { Input, AutoComplete, Flex } from "antd";

import { fetchSearch } from "store/slices/productSlice";
import useSmartNavigate from "hooks/useSmartNavigate";
import { formatCurrency, formatWeightWithUnit } from "helpers/formatter";
import CartQtyButtonGroup from "components/cart/CartQtyButtonGroup";

const SearchWithSuggest = ({ listHeight = 700, isDesktop = true }) => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.product.search);
  const { navigate } = useSmartNavigate();

  const handleSuggest = (s) => {
    dispatch(fetchSearch(s));
  };

  const handleGoSearchPage = (s) => {
    console.log(`К поиску: ${s}`)
  };

  const handleGoProductPage = (s, option) => {
    navigate(`/product/${option.path}`);
  };

  return (
    <AutoComplete
      options={data.products?.map((p) => ({
        label: <SuggestItem item={p} isDesktop={isDesktop} />,
        value: p.name,
        path: `${p.product_id}/${p.slug}`
      }))}
      
      onSelect={handleGoProductPage}
      onSearch={handleSuggest}
      size="large"
      style={{ width: "100%" }}
      listHeight={listHeight}
    >
      <Input.Search
        size="large"
        placeholder="поиск по товарам"
        onSearch={handleGoSearchPage}
        enterButton
      />
    </AutoComplete>
  );
}

export default SearchWithSuggest;



const SuggestItem = ({ item, isDesktop }) => (
  <Flex justify="space-between" align="center" style={{ padding: "10px 0" }}>
    <Flex>
      <img
        alt={item.name}
        src={item.thumb}
        style={{ maxWidth: 60, maxHeight: 60 }}
      />
      <div style={{ whiteSpace: "normal", marginLeft: 20 }}>
        <div style={{ fontSize: "1.1em" }}>
          {`${item.name}, ${formatWeightWithUnit(item.weight)}`}
        </div>
        {isDesktop ? (
          <strong>{formatCurrency(item.price)}</strong>
        ) : (
          <Flex justify="space-between" align="center">
            <strong>{formatCurrency(item.price)}</strong>
            <CartQtyButtonGroup item={item} size="small" />
          </Flex>
        )}
      </div>
    </Flex>
    {isDesktop ? (
      <Flex style={{ paddingLeft: 20 }}>
        <CartQtyButtonGroup item={item} size="small" />
      </Flex>
    ) : ""}
  </Flex>
);
